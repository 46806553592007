// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2022 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

$primary: #00C853
$primary-dark: #009624
$primary-light: #5EFC82
$secondary: #00B8D4
$secondary-dark: #0088A3
$secondary-light: #62EBFF
$error: #B71C1C
$error-dark: #7F0000
$error-light: #F05545
$warning: orange

$border-color: #DDD
$text-color: #212121
$background: #FAFAFA
$background-dark: #E7E7E7
$inverted-text-color: $background
$font-stack: Raleway, sans-serif
