// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2022 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

.switch
    display: flex

    width: 100%
    height: 2rem

    cursor: pointer

    border: 1px solid $error-light
    border-radius: .25rem
    background-color: $background

    box-sizing: border-box

    > .box
        display: flex
        box-sizing: border-box
        width: 50%
        height: 100%

        transition: .5s
        text-align: center

        border-radius: .15rem 0 0 .15rem
        background-color: $error-light
        color: $inverted-text-color

        align-items: center

        > .text
            box-sizing: border-box
            width: 100%

            text-align: center
            vertical-align: middle

            color: $inverted-text-color
            font-size: 1rem

            user-select: none

            .on
                display: none

            .off
                display: inline


    &[data-active=true]
        border: 1px solid $primary
        > .box
            background-color: $primary
            transform: translateX(100%)

            border-radius: 0 .15rem .15rem 0

            .on
                display: inline

            .off
                display: none
