// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2022 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> div.client
    display: flex

    > div.sidebar
        vertical-align: top
        text-align: center
        width: 8rem
        margin-right: 1rem

        > *
            margin-bottom: 1rem

        @import avatar
        @import started

    > div.info
        vertical-align: top
        flex: 1

        > div.instances
            +instancelist

        input.fingerprint
            font-family: "Fira Code", monospace
            font-size: 0.8em

    @media screen and (max-width: 40rem)
        flex-wrap: wrap

        > div.sidebar, > div.info
            width: 100%
            margin-right: 0
