@font-face
    font-family: 'Raleway'
    font-style: normal
    font-weight: 300
    src: local('Raleway Light'), local('Raleway-Light'), url('../../fonts/raleway-light.woff2') format('woff2')

@font-face
    font-family: 'Raleway'
    font-style: normal
    font-weight: 400
    src: local('Raleway'), local('Raleway-Regular'), url('../../fonts/raleway-regular.woff2') format('woff2')

@font-face
    font-family: 'Raleway'
    font-style: normal
    font-weight: 700
    src: local('Raleway Bold'), local('Raleway-Bold'), url('../../fonts/raleway-bold.woff2') format('woff2')


@font-face
    font-family: 'Fira Code'
    src: url('../../fonts/firacode-regular.woff2') format('woff2')
    font-weight: 400
    font-style: normal

@font-face
    font-family: 'Fira Code'
    src: url('../../fonts/firacode-bold.woff2') format('woff2')
    font-weight: 700
    font-style: normal
