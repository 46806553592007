// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2022 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> .plugin
    > .upload-box
        +upload-box

        width: calc(100% - 1rem)
        height: 10rem
        margin: .5rem
        border-radius: .5rem
        box-sizing: border-box

        border: .25rem dotted $primary

        > svg.upload
            width: 8rem
            height: 8rem

            opacity: .5

        > input.file-selector
            width: 100%
            height: 100%

        &:not(.uploading):hover, &:not(.uploading).drag
            border: .25rem solid $primary
            background-color: $primary-light

            > svg.upload
                opacity: 1

        &.uploading
            > svg.upload
                visibility: hidden
            > input.file-selector
                cursor: default

    > div.instances
        +instancelist
