// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2022 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> div.avatar-container
    +upload-box

    width: 8rem
    height: 8rem
    border-radius: 50%

    @media screen and (max-width: 40rem)
        margin: 0 auto 1rem

    > img.avatar
        position: absolute
        display: block
        max-width: 8rem
        max-height: 8rem
        user-select: none

    > svg.upload
        visibility: hidden

        width: 6rem
        height: 6rem

    > input.file-selector
        width: 8rem
        height: 8rem

    &:not(.uploading)
        &:hover, &.drag
            > img.avatar
                opacity: .25

            > svg.upload
                visibility: visible

        &.no-avatar
            > img.avatar
                visibility: hidden

            > svg.upload
                visibility: visible
                opacity: .5

    &.uploading
        > img.avatar
            opacity: .25
